import axios from 'axios';
import qs from 'qs';

import { toast } from 'react-toastify';

import axiosApi, { baseUrl } from './api'
import { jwtHandler } from './Handlers';

export default function login(usuario, senha) {

    const options = {
        method: 'POST',
        baseURL: baseUrl,
        url: 'oauth/token',
        timeout: 5000,
        auth: {
            username: 'internarService',
            password: 'connect2000hengelebpbiomonte'
        },
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            'grant_type': 'password',
            'username': usuario,
            'password': senha,
        }),
    };

    return new Promise((resolve, reject) => {

        axios(options).then(response => {

            if (jwtHandler(response.data.access_token).userType === 'INTERNALUSER') {
                localStorage.setItem('token', response.data.access_token);
                resolve('/home');

            } else {
                toast.error('Tipo De Usuario Invalido');
                reject('Tipo De Usuario Invalido');
            }

        }).catch(erro => {

            if (erro.response && erro.response.data.error_description === 'Bad credentials') {
                toast.error('Usuario ou Senha Invalidos !');
            } else {
                toast.error('Servidor Offline');
            }
    
            reject(erro.response);

        });

    });

}

export function logout() {

    const token = localStorage.getItem('token');

    axiosApi().delete(`tokens/revoke/${token}`).finally(() => {
        localStorage.clear();
        window.location.href = '/login';
    });

}
