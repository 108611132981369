import React, { useState } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { InputMask } from 'primereact/inputmask';
import Loader from 'react-loader-spinner'

import login from '../../services/LoginService';

export default function LoginForm() {

    const [cpfLogin, setCpfLogin] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [usuarioLogin, setUsuarioLogin] = useState({
        usuario: '',
        senha: ''
    });

    const handleUsuarioLogin = (event) => {
        event.persist();
        setUsuarioLogin(usuarioLogin => ({ ...usuarioLogin, [event.target.name]: event.target.value }));
    }

    const handleUsuarioLoginCpf = (event) => {
        setUsuarioLogin(usuarioLogin => ({ ...usuarioLogin, [event.target.name]: event.target.value }));
    }

    const entrar = async (event) => {

        event.preventDefault();

        setShowSpinner(true);
        login(usuarioLogin.usuario, usuarioLogin.senha).then(response => {

            window.location.href = response;

        }).finally(() => setShowSpinner(false))

        setUsuarioLogin(usuarioLogin => ({ ...usuarioLogin, senha: '' }));

    }

    return (
        <form onSubmit={entrar}>

            <div className="group tipoLogin">
                <div className="p-field-radiobutton">
                    <RadioButton inputId="usuario" value={false} onChange={(e) => setCpfLogin(e.value)} checked={cpfLogin === false}
                        disabled={usuarioLogin.usuario.length > 0} />
                    <label htmlFor="usuario">Usuario</label>
                </div>
                <div className="p-field-radiobutton">
                    <RadioButton inputId="cpf" value={true} onChange={(e) => setCpfLogin(e.value)} checked={cpfLogin === true}
                        disabled={usuarioLogin.usuario.length > 0} />
                    <label htmlFor="cpf">CPF</label>
                </div>
            </div>

            <div className="group">
                <label className="label">{cpfLogin ? 'CPF' : 'Usuario'}</label>
                {cpfLogin ?
                    <InputMask type="text" mask="999.999.999-99" className="input" name="usuario"
                        value={usuarioLogin.usuario} onChange={handleUsuarioLoginCpf} />
                    :
                    <input required type="text" className="input" name="usuario"
                        value={usuarioLogin.usuario} onChange={handleUsuarioLogin} />
                }
            </div>

            <div className="group">
                <label className="label">Senha</label>
                <input required type="password" className="input" name="senha"
                    value={usuarioLogin.senha} onChange={handleUsuarioLogin} />
            </div>

            <div className="group">
                <input type="submit" className="button" value="Entrar" />
            </div>

            {
                showSpinner ?
                    <div id="cover-spin">
                        <div className="loadingSpinner">
                            <Loader type="Bars" color="#324ea8" height={100} width={100} />
                        </div>
                    </div>
                    : ''
            }

        </form>
    )
}
